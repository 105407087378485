import React from "react";
import Education from "./education";
import Career from "./career";
import Personal from "./personal";

export default function AboutMe(props) {
	return (
		<>
			<Education />
			<Career />
			<Personal />
		</>
	);
}
