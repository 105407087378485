import React from "react";

export default function Personal(props) {
	return (
		<p>
			In my personal life, I enjoy skiing, hiking, programming, Home DIY,
			and snake-keeping. My wife is a veterinarian and together, we have 2
			cats, 1 dog, and 2 snakes. Snakes are my favorite animal and I find
			them to be such unique creatures.
		</p>
	);
}
