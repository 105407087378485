import React from "react";
import Navigation from "./navbar.js";

export default function Header(props) {
	return (
		<>
			<Navigation />
		</>
	);
}
